/*
 * script.js
 */
//import $ from 'jquery';
//import * as bootstrap from 'bootstrap';

window.jQuery = $;
window.$ = $;

$(document).ready(function () {
    // Initialize tooltips
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(function (tooltipTriggerEl) {
        new bootstrap.Tooltip(tooltipTriggerEl);
    });

    // Popovers
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(function (popoverTriggerEl) {
        new bootstrap.Popover(popoverTriggerEl);
    });

    // SmoothScroll
    $('.smooth-scroll').click(function () {
        $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top}, 500);
        return false;
    });

    /* Funzione per il cambio icona a destra dell'accordion */
    /* Funzione per il cambio icona a destra dell'accordion */
    $('.collapse').on('shown.bs.collapse', function (e) {
        $(this).closest('.accordion-item').find('.more-less').removeClass('fa-plus').addClass('fa-minus');
    });
    $('.collapse').on('hidden.bs.collapse', function (e) {
        $(this).closest('.accordion-item').find('.more-less').removeClass('fa-minus').addClass('fa-plus');
    });


    /* Funzione per cambiare accordion da select in pagina FAQ */
    if ($('.faq__tabs').length) {
        $("#tabNav").change(function () {
            $('.faq__tabs__navigator button[data-bs-target="#' + $(this).val() + '"]').click();
        });
    }

    var $error_message = $('.errors__message');
    if ($error_message.length)
        var sticky = new Waypoint.Sticky({
            element: $error_message[0]
        });

});
