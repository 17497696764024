import $ from 'jquery';

window.jQuery = $;
window.$ = $;

$(document).ready(function() {
    $('.open-mobile-nav').bigSlide({
        'menu': '#mobile-nav',
        'push': '.push-by-mobile-nav',
        'side': 'right',
        'menuWidth': '260px',
        'speed': '600',
        'easyClose': true
    });
});