/***** function-lib.js *****/


/**
 * empty(myVar) => return TRUE or FALSE if MyVar is empty.
 *
 * @param data
 * @returns {boolean}
 */
function empty(data) {
    if (typeof (data) == 'number' || typeof (data) == 'boolean') {
        return false;
    }
    if (typeof (data) == 'undefined' || data === null) {
        return true;
    }
    if (typeof (data.length) != 'undefined') {
        return data.length === 0;
    }
    var count = 0;
    for (var i in data) {
        if (data.hasOwnProperty(i)) {
            count++;
        }
    }
    return count === 0;
}

/**
 * Open the URL in another browser tab and assign to it the focus
 *
 * @param url
 * @constructor
 */
function OpenInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
}

/**
 * Return if the device is mobile
 *
 * @returns {boolean}
 */
function isMobile(tmobileBreakpoint) {
    if (tmobileBreakpoint === undefined) {
        tmobileBreakpoint = 981
    }
    var mobileBreakpoint = tmobileBreakpoint;
    var md = new MobileDetect(window.navigator.userAgent);
    var mobile_agent = !empty(md.mobile());
    var mobile_screen = (jQ(window).innerWidth() < mobileBreakpoint);
    return (mobile_agent && mobile_screen);
}


String.prototype.trimRight = function (charlist) {
    if (charlist === undefined)
        charlist = "\s";
    return this.replace(new RegExp("[" + charlist + "]+$"), "");
};

String.prototype.trimLeft = function (charlist) {
    if (charlist === undefined)
        charlist = "\s";
    return this.replace(new RegExp("^[" + charlist + "]+"), "");
};

String.prototype.trim = function (charlist) {
    return this.trimLeft(charlist).trimRight(charlist);
};
